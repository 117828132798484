import React from 'react'
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Meta from '../components/Meta'
import RichText from '../components/RichText'

const PageTemplate = ({ data }) => {

  const { bodyCopy } = data.contentfulPage

  return (
    <Layout hasName>
      <Meta />
      <article className="has-underlines">
        <RichText content={bodyCopy} />
      </article>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const pageQuery = graphql`
  query PageTemplateQuery($slug: String!){
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      bodyCopy {
        raw
      }
    }
  }
`
