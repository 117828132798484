// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Hooks
// ....
// Components
import Img from "../components/Img";
import Project from "../components/project";

// Data
function useStaticContent() {
  const data = useStaticQuery(graphql`
    {
      allContentfulAsset {
        nodes {
          contentful_id
          file {
            url
          }
          #fluid(maxHeight: 1080) {
          #  ...GatsbyContentfulFluid_noBase64
          #}
        }
      }
      allContentfulProject {
        nodes {
          ...ContentfulProjectFragment
        }
      }
    }
  `);
  return data
};

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <i>{children}</i>;

// BLOCKS
const P = ({ children }) => <p>{children}</p>;
const H1 = ({ children }) => <h1>{children}</h1>;
const H2 = ({ children }) => <h2>{children}</h2>;
const H3 = ({ children }) => <h3>{children}</h3>;
const H4 = ({ children }) => <h4>{children}</h4>;
const Quote = ({ children }) => <blockquote>{children}</blockquote>;

const Image = (props) => {
  const { file, title } = props.data.target.fields
  const { contentful_id } = props.data.target.sys;
  const { allContentfulAsset } = useStaticContent();
  const asset = allContentfulAsset.nodes.find(node => node.contentful_id === contentful_id);
  return (
    asset && (
      <div className="rich-text-asset">
        <Img {...asset} />
      </div>
    )
  )
}

const Hyperlink = (props) => {
  return (
    <a href={props.data.uri} target="_blank" rel="noopener noreferrer">{props.children}</a>
  )
}

const ProjectContentType = ({ contentful_id }) => {
  const { allContentfulProject } = useStaticContent();
  const entry = allContentfulProject.nodes.find(node => node.contentful_id === contentful_id);

  return (
    entry && (
      <Project {...entry} />
    )
  )
}

const EmbeddedEntry = ({ data }) => {
  const { fields, sys } = data.target;
  const isProject = sys.contentType.sys.id === "project";

  return isProject ? (
    <ProjectContentType {...sys} />
  ) : null
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image {...node}>{children}</Image>,
    [INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  }
};

const RichText = ({ content }) => (
	content && (
		renderRichText(content, options)
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText
